import { Grid, Typography } from '@mui/material'

type FormTitlePropsType = {
  title: string
  subtitle?: string
  align?: 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined
}

const FormTitle = ({ title, subtitle = '', align = 'left' }: FormTitlePropsType) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      wrap="nowrap"
      sx={{ mb: 3, mt: { xs: 2 }, flexShrink: '1' }}
    >
      <Typography variant="h4" align={align} sx={{ whiteSpace: 'pre-line' }}>
        {title}
      </Typography>
      <Typography variant="body2" align={align} sx={{ mt: 1, whiteSpace: 'pre-line' }}>
        {subtitle}
      </Typography>
    </Grid>
  )
}

export default FormTitle
