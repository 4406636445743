import { createTheme } from '@mui/material'
import { frFR } from '@mui/x-date-pickers/locales'
import BienEtreSocialRegular from '../fonts/bien-etre-social-regular.otf'

export const WLtheme = createTheme(
  {
    typography: {
      fontFamily: ['Inter', 'Bien-Etre-Social-Regular', 'sans-serif'].join(','),
      h1: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h2: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h3: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h4: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h5: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h6: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      body1: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        color: 'rgba(0,0,0,0.87)',
        // fontWeight: '500 !important',
        fontSize: 16,
        letterSpacing: '0.15px',
        // lineHeight: '24px',
      },
      body2: {
        xs: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          color: 'rgba(0,0,0,0.6)',
          fontSize: 14,
          letterSpacing: '0.4px',
          // lineHeight: '20px',
        },
        md: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          color: 'rgba(0,0,0,0.6)',
          fontSize: 12,
          letterSpacing: '0.4px',
          // lineHeight: '20px',
        },
      },
      caption: {
        color: 'rgba(0,0,0,0.6)',
        fontFamily: ['Inter', 'sans-serif'].join(','),
        letterSpacing: '0.33px',
        // lineHeight: '17px',
      },
    },
    shape: {
      borderRadius: 4,
    },
    palette: {
      primary: {
        main: '#000000',
        light: '#00000005',
        dark: '#00000080',
      },
      success: {
        main: '#007A59',
        light: '#007A59',
        dark: '#007A59',
      },
      warning: {
        main: '#FA6400',
        light: '#FEEFE6',
        dark: '#663C00',
      },
      info: {
        main: '#3E83F5',
        light: '#ECF2FE',
        dark: '#5193AC',
        contrastText: '#0D3C61',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Bien-Etre-Social-Regular';
            src: url(${BienEtreSocialRegular}) format('opentype');
          }
        `,
      },
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            backgroundColor: 'white',
            borderRadius: '25px',
            color: 'rgba(0,0,0,0.6)',
            border: '1px solid rgba(0,0,0,0.54)',
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          },
          containedPrimary: {
            backgroundColor: 'black',
            borderRadius: '25px',
            '&:hover': {
              color: 'white',
              backgroundColor: '#272829',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#3E83F5',
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
            fontWeight: 500,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            paddingRight: '4px',
            paddingLeft: '4px',
            paddingBottom: '8px',
            paddingTop: '8px',
          },
          head: {
            padding: '4px',
          },
        },
      },
    },
  },
  frFR,
)

export const WStheme = createTheme(
  {
    typography: {
      fontFamily: ['Inter', 'Bien-Etre-Social-Regular', 'sans-serif'].join(','),
      h1: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h2: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h3: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h4: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      h5: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        lineHeight: '0.2',
      },
      h6: {
        fontFamily: ['Bien-Etre-Social-Regular', 'sans-serif'].join(','),
        letterSpacing: '0.25px',
        // lineHeight: '42px',
      },
      body1: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        color: 'rgba(0,0,0,0.87)',
        // fontWeight: '500 !important',
        fontSize: 16,
        letterSpacing: '0.15px',
        // lineHeight: '24px',
      },
      body2: {
        xs: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          color: 'rgba(0,0,0,0.6)',
          fontSize: 14,
          letterSpacing: '0.4px',
          // lineHeight: '20px',
        },
        md: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          color: 'rgba(0,0,0,0.6)',
          fontSize: 12,
          letterSpacing: '0.4px',
          // lineHeight: '20px',
        },
      },
      caption: {
        color: 'rgba(0,0,0,0.6)',
        fontFamily: ['Inter', 'sans-serif'].join(','),
        letterSpacing: '0.33px',
        // lineHeight: '17px',
        display: 'block',
        fontSize: 10,
      },
    },
    shape: {
      borderRadius: 4,
    },
    palette: {
      primary: {
        main: '#75D4F7',
        light: '#F4FCFF',
        dark: '#5193AC',
      },
      success: {
        main: '#007A59',
        light: '#007A59',
        dark: '#007A59',
      },
      warning: {
        main: '#FA6400',
        light: '#FEEFE6',
        dark: '#663C00',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Bien-Etre-Social-Regular';
            src: url(${BienEtreSocialRegular}) format('opentype');
          }
        `,
      },
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            color: 'black',
            '&:hover': {
              backgroundColor: '#75d4f780',
            },
          },
          containedPrimary: {
            '&:hover': {
              color: 'white',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#3E83F5',
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
            fontWeight: 500,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            paddingRight: '4px',
            paddingLeft: '4px',
            paddingBottom: '8px',
            paddingTop: '8px',
          },
          head: {
            padding: '4px',
          },
        },
      },
    },
  },
  frFR,
)
