import { Grid } from '@mui/material'

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    background: '#eff2f4',
    height: '45px',
    border: '0.5px solid #e2e2e2',
    minWidth: '315px',
    lineHeight: '15px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    fontFamily: 'Inter!important',
    fontWeight: 600,
    borderRadius: '2px!important',
  },
  leftWrapper: {
    position: 'relative',
    color: '#db1c3b',
    background: '#ffffff',
    border: 'solid 0.1px white',
    paddingLeft: '10px',
    fontSize: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '45%',
    fontFamily: 'Inter!important',
    fontWeight: 600,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    '&::after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '-1px',
      right: '-14px',
      borderTop: '22.7px solid transparent',
      borderBottom: '22.7px solid transparent',
      borderLeft: '14px solid #ffffff',
    },
  },
  rightWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-end',
    textAlign: 'center',
    ml: 2,
    width: '47%',
    fontFamily: 'Inter!important',
    fontWeight: 600,
    fontSize: 18,
  },
}

type CallUsPropsType = {
  phone: string
}

const CallUs = ({ phone }: CallUsPropsType) => {
  const hrefPhone = `tel:+33${phone.slice(1)}`
  return (
    <Grid sx={styles.container}>
      <Grid sx={styles.leftWrapper}>
        <Grid sx={{ flexDirection: 'column' }}>
          <div
            style={{
              fontFamily: 'var(--fontFamilySemiBold)',
              fontSize: '14px',
            }}
          >
            Besoin d'aide ?
          </div>
          <div style={{ color: 'black', fontSize: '9px' }}>Contactez un conseiller</div>
        </Grid>
      </Grid>

      <Grid sx={styles.rightWrapper}>
        <a href={hrefPhone} style={{ color: 'black', textDecoration: 'none' }}>
          {phone.match(/.{2}/g)?.join(' ')}
        </a>
      </Grid>
    </Grid>
  )
}

export default CallUs
