import { useEffect } from 'react'
import { CallUs, Drawer, Header } from '.'
import { Grid, Typography, useTheme, Link, Rating } from '@mui/material'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { FormData, StepType } from '../utils/sharedTypes'
import { useLocation } from 'react-router-dom'
import { learningPhoneNumber, santePhoneNumber } from '../utils/constants'
import TagManager from 'react-gtm-module'

type LayoutPropsType = {
  children: React.ReactNode
  form: FormData
  steps: StepType[]
}

const Layout = ({ form, children, steps }: LayoutPropsType) => {
  const theme = useTheme()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    TagManager.dataLayer({
      dataLayer: {
        event: 'Pageview',
        environment: import.meta.env.VITE_ENV === 'PRODUCTION',
        pagePath: window.location.pathname,
        pageTitle: `${window.document.title} - ${window?.location?.pathname?.split('/')?.[1] || ''}`,
        formationId: form.product?.product_code,
        formationName: form.product?.name,
        formationValue: form.product?.listing_price,
        formationCurrency: 'EUR',
      },
    })
  }, [location.pathname])

  const phoneNumber = form.theme === 'sante' ? santePhoneNumber : learningPhoneNumber

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {location.pathname.includes('/sante')
            ? `Walter Santé${form.product ? ' - ' + form.product.name : ''}`
            : `Walter Learning${form.product ? ' - ' + form.product.name : ''}`}
        </title>
      </Helmet>
      <Grid
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: 2,
          mr: 4,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <CallUs phone={phoneNumber} />
      </Grid>
      <Drawer form={form} steps={steps} />
      <Grid container justifyContent="end" sx={{ minHeight: '100vh' }}>
        <Header form={form} steps={steps} />
        <Grid item xs={12} sm={8} md={9} sx={{ float: 'right', alignContent: 'right', justifySelf: 'right', p: 2 }}>
          <Grid item container justifyContent="center" sx={{ height: '100%', pt: { sm: 10 } }}>
            <Grid item xs={12} sm={8} md={7} sx={{ position: 'relative' }}>
              {children}
            </Grid>
            <Grid item xs={12} sm={8} md={7} sx={{ mt: 4, alignItems: 'flex-end', display: 'flex' }}>
              <Grid container direction="column">
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ display: { xs: 'flex', sm: 'none' } }}
                >
                  <Grid item sx={{ pb: 1 }}>
                    <Typography variant="body2">
                      Besoin d'aide ? {''}
                      <Link href={`tel:+33${phoneNumber.slice(1)}`}>{phoneNumber.match(/.{2}/g)?.join(' ')}</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      4.7 sur Google
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Rating value={4.7} precision={0.1} readOnly sx={{ display: 'flex' }} />
                  </Grid>
                </Grid>
                <Grid item sx={{ mt: { xs: 4, md: 0 } }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.disabled,
                      fontSize: 10,
                      textAlign: { xs: 'center', md: 'left' },
                    }}
                  >
                    {location.pathname.includes('/sante/') ? 'Walter Santé' : 'Walter Learning'} est une marque de ELOCE
                    SAS • NDA 93 13 17427 13 • Processus certifié Qualiopi •{' '}
                    <Link href="https://walter-learning.com/privacy" target="_blank">
                      Politique de confidentialité des Données
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HelmetProvider>
  )
}

export default Layout
